import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

import arrowDown from "../../../assets/images/icons/arrowDown.svg";
import off from "../../../assets/images/icons/off.svg";
//import mazharLogo from "../../../assets/images/logos/greenLogoNoText.svg";
//import mazhrLogoText from "../../../assets/images/logos/mazhr-logo-text.svg";
import mazharLogo from "../../../assets/images/logos/apex_analytics_green_logo.svg";
import mazhrLogoText from "../../../assets/images/logos/apex-analytics-text.svg";

import { PrimaryMenu, SecondaryMenu } from "./NavData";
import Logout from "./Logout";
import { AiTwotoneLock } from "react-icons/ai";
import * as Constants from "../../../constants";
import { logEvent } from "../../../analytics";

function Sidebar() {
  const isLockedFromPrimaryMenu = useSelector(
    (state) => state.userData.isLockedFromPrimaryMenu
  );
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(
    localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_MENU)
      ? JSON.parse(
          localStorage.getItem(Constants.LOCAL_STORAGE_KEY_SELECTED_MENU)
        )
      : {}
  );
  const [selectedSubMenu, setSelectedSubmenu] = useState(-1);
  const [selectedSubMenuItem, setSelectedSubmenuItem] = useState(-1);

  const getActivePath = (menuLinks) => {
    if (menuLinks) {
      menuLinks.forEach((element) => {
        if (element.path === location.pathname) {
          return true;
        }
      });
    }
    return false;
  };

  const onSelectedMenu = (menuTitle, subMenuTitle = null) => {
    if (subMenuTitle !== null) {
      logEvent("menu_clicked", menuTitle + "-" + subMenuTitle);
    } else {
      logEvent("menu_clicked", menuTitle);
    }

    localStorage.setItem(
      Constants.LOCAL_STORAGE_KEY_SELECTED_MENU,
      JSON.stringify({ menu: menuTitle, subMenu: subMenuTitle })
    );
    setSelectedMenu({ menu: menuTitle, subMenu: subMenuTitle });
  };

  const selectSubmenu = (index) => {
    if (index === selectedSubMenu && selectedSubMenu !== -1) {
      setSelectedSubmenu(-1);
    } else {
      setSelectedSubmenu(index);
      setSelectedSubmenuItem(-1);
    }
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar">
        <div className="primary-menu">
          <li className="nav-item">
            <div className="link logo" style={{padding:0}}>
              <img className="company-logo" src={mazharLogo} alt="mazhr-logo" />
            </div>
          </li>
          {PrimaryMenu.map((item, subMenuIndex) => {
            if (item.enabled) {
              return item.submenu.length < 2 ? (
                <li
                  key={subMenuIndex}
                  className={`nav-item ${
                    isLockedFromPrimaryMenu && item.canBeLocked && "locked"
                  }`}
                >
                  <NavLink
                    activeclassname="active"
                    className="link"
                    to={item.submenu[0].path}
                  >
                    <div className="visible">{item.icon}</div>
                    {isLockedFromPrimaryMenu && item.canBeLocked ? (
                      <AiTwotoneLock size="12" />
                    ) : null}
                  </NavLink>
                </li>
              ) : (
                <li
                  key={subMenuIndex}
                  className={`nav-item ${
                    isLockedFromPrimaryMenu && item.canBeLocked && "locked"
                  }`}
                >
                  <div
                    className={
                      selectedMenu?.menu === item.title ? "link active" : "link"
                    }
                  >
                    <div className="visible">{item.icon}</div>
                    {isLockedFromPrimaryMenu && item.canBeLocked ? (
                      <AiTwotoneLock size="12" />
                    ) : null}
                  </div>
                  <div
                    className={
                      selectedSubMenu === subMenuIndex
                        ? "subMenuItemsWrapper"
                        : "subMenuItemsWrapper hide"
                    }
                  >
                    {item.submenu &&
                      item.submenu.map((menuItem, menuItemIndex) => {
                        return (
                          <NavLink
                            className={
                              selectedSubMenu === subMenuIndex &&
                              selectedSubMenuItem === menuItemIndex
                                ? "subMenuItem"
                                : "subMenuItem selected"
                            }
                            key={menuItemIndex}
                            to={menuItem.path}
                          >
                            <p className="m-0 indicators">o</p>
                          </NavLink>
                        );
                      })}
                  </div>
                </li>
              );
            } else {
              return "";
            }
          })}
        </div>
        <div className="secondary-menu">
          {SecondaryMenu.map((item, index) => {
            if (item.enabled) {
              return (
                <li key={index} className="nav-item">
                  <NavLink
                    activeclassname="active"
                    className="link"
                    to={item.path}
                  >
                    <div className="visible">{item.icon}</div>
                  </NavLink>
                </li>
              );
            } else {
              return "";
            }
          })}
          <li className="nav-item">
            <div className="link">
              <div className="visible">
                <img className="navIcon" src={off} alt="logout" />
              </div>
            </div>
          </li>
        </div>

        <div className="hidden-sidebar">
          <div className="primary-menu">
            <li className="nav-item">
              <img className="link logo" style={{padding: 0}} src={mazhrLogoText} alt="mazhr-logo" />
            </li>
            {PrimaryMenu.map((item, subMenuIndex) => {
              if (item.enabled) {
                return item.submenu.length < 2 ? (
                  <li
                    key={subMenuIndex}
                    className={`nav-item ${
                      isLockedFromPrimaryMenu && item.canBeLocked && "locked"
                    }`}
                  >
                    <NavLink
                      activeclassname="active"
                      className="link"
                      to={item.submenu[0].path}
                      onClick={() => onSelectedMenu(item.title)}
                    >
                      <div className="navItemTitle">
                        <p className="m-0">{item.title}</p>
                      </div>
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={subMenuIndex}
                    className={`nav-item ${
                      isLockedFromPrimaryMenu && item.canBeLocked && "locked"
                    }`}
                  >
                    <div
                      className={
                        getActivePath(item.submenu) ? "link active" : "link"
                      }
                    >
                      <div
                        className="submenu"
                        id={subMenuIndex}
                        onClick={() => selectSubmenu(subMenuIndex)}
                      >
                        <div className="navItemTitle">
                          <p className="m-0">{item.title}</p>
                          <img
                            className="submenuTogglerClosed"
                            src={arrowDown}
                            alt="expand submenu"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedSubMenu === subMenuIndex
                          ? "subMenuItemsWrapper"
                          : "subMenuItemsWrapper hide"
                      }
                    >
                      {item.submenu &&
                        item.submenu.map((menuItem, menuItemIndex) => {
                          return (
                            <NavLink
                              className={
                                selectedMenu?.menu === item.title &&
                                selectedMenu?.subMenu === menuItem.title
                                  ? "subMenuItem selected"
                                  : "subMenuItem"
                              }
                              key={menuItemIndex}
                              to={menuItem.path}
                              onClick={() =>
                                onSelectedMenu(item.title, menuItem.title)
                              }
                            >
                              {menuItem.title}
                            </NavLink>
                          );
                        })}
                    </div>
                  </li>
                );
              } else {
                return "";
              }
            })}
          </div>
          <div className="secondary-menu">
            {SecondaryMenu.map((item, index) => {
              if (item.enabled) {
                return (
                  <li key={index} className="nav-item">
                    <NavLink
                      activeclassname="active"
                      className="link"
                      to={item.path}
                      onClick={() => onSelectedMenu(item.title, null)}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              } else {
                return "";
              }
            })}
            <li className="nav-item">
              <div className="link">
                <Logout />
              </div>
            </li>
          </div>
        </div>
      </div>
      <div className="sidebar-spacer"></div>
    </div>
  );
}

export default Sidebar;
