import { useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../components/reusableElementsComponents/Button";
import Loading from "../../components/reusableElementsComponents/Loading";
import loading from "../../assets/images/icons/loading.gif";
// import GoogleIcon from "../../assets/images/icons/socialMediaIcons/Google.svg";
// import FacebookIcon from "../../assets/images/icons/socialMediaIcons/Facebook.svg";
// import TwitterIcon from "../../assets/images/icons/socialMediaIcons/Twitter.svg";

import {
  FormControlLabel,
  Checkbox,
  FormControl,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";

import * as Constants from "./../../constants";
import TalentsApiService from "../../TalentsApiService";

import { inputLabelClasses } from "@mui/material/InputLabel";
import OnboardingStepper from "../../components/layoutComponents/navigation/OnboardingStepper";

function RegistrationStage1() {
  // GET DATA FROM REDUX STORE
  const pageData = useSelector((state) =>
    state.contentfulData.talentAppData
      ? state.contentfulData.talentAppData[
          "Talent app - Registration page (stage 1)"
        ]
      : null
  );

  let companyData, teamData;
  const corporate = useSelector((state) => state.teamData?.all?.[0]) || null;
  if (corporate) {
    companyData = corporate.company;
    teamData = corporate.team;
  }

  const invitationData = useSelector((state) => state.userData?.invitationData);
  // JSON.parse(localStorage.getItem(Constants.MAZHR_INVITATION_DATA)) || null;
  let userInvitationTeamHash, userInvitationTemporaryToken,userInvitationPoolHash;
  if (invitationData) {
    if(invitationData.team){
      userInvitationTeamHash = invitationData.team;
      userInvitationTemporaryToken = invitationData["user-token"];
    }else if(invitationData.hash){
      userInvitationPoolHash = invitationData.hash;
    }
  }

  // DECLARE STATE VARIABLES
  const [formValues, setFormValues] = useState({
    first: "",
    last: "",
    email: "",
    password: "",
    re_password: "",
    terms_conditions_agreement: true,
  });
  const [formErrors, setFormErrors] = useState({
    first: false,
    last: false,
    email: false,
    password: false,
    re_password: false,
    terms_conditions_agreement: false,
    processFailed: false,
  });
  const [formErrorMessages, setFormErrorMessages] = useState({
    first: "Name should only contain letters",
    last: "Name should only contain letters",
    email: "Email address must include . and @",
    password:
      "Password must contain at least 8 characters, a capital letter, a number and a special symbol",
    re_password:
      "Password must contain at least 8 characters, a capital letter, a number and a special symbol",
    terms_conditions_agreement:
      "Please agree to our Terms of Service and Privacy policy.",
    processFailed:
      "Something went wrong. Please try again. Or contact our customer support.",
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  // ALL FUNCTIONS
  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInvitationTemporaryToken) {
      talentsServiceObj.LoadUserDataByTemporaryToken(
        userInvitationTemporaryToken,
        (callbackData) => {
          callbackData &&
            setFormValues((prevFormValues) => {
              const { first, last, email, ...rest } = prevFormValues;
              return {
                first: callbackData.first || first,
                last: callbackData.last || last,
                email: callbackData.email || email,
                ...rest,
              };
            });
        },
        setLoader
      );
    }
  }, [userInvitationTemporaryToken, talentsServiceObj]);

  const emailAddressExist = (exist) => {
    if (exist) {
      setFormErrors({ ...formErrors, email: true });
      setFormErrorMessages({
        ...formErrorMessages,
        email: "Email address already exist",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (!value) {
      updateFormErrorsObject(name, true);
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    let valid = false;
    if (value !== undefined && value.length > 0) {
      valid = validateFieldValues(name, value);
    }

    if (valid && name === "email") {
      talentsServiceObj.EmailExist(value, emailAddressExist);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
    validateFieldValues(name, checked);
  };

  const updateFormErrorsObject = (name, value) => {
    setFormErrors({
      ...formErrors,
      [name]: value,
    });
  };

  const validateFieldValues = (name, value) => {
    let valid = false;
    switch (name) {
      case "first":
      case "last":
        valid = Constants.VALID_NAME.test(value);
        if (!valid) {
          setError(true);
        }
        updateFormErrorsObject(name, !valid);
        break;
      case "email":
        valid = Constants.VALID_EMAIL.test(value);
        if (!valid) {
          setError(true);
        }
        updateFormErrorsObject(name, !valid);
        if (!valid) {
          setError(true);
        }
        break;
      case "password":
        valid = Constants.VALID_PASSWORD.test(value);
        if (!valid) {
          setError(true);
        }
        updateFormErrorsObject(name, !valid);
        break;
      case "re_password":
        valid = Constants.VALID_PASSWORD.test(value);
        if (!valid || formValues.password !== value) {
          setError(true);
          updateFormErrorsObject("re_password", true);
          setFormErrorMessages({
            ...formErrorMessages,
            re_password: "Passwords must match",
          });
        } else {
          updateFormErrorsObject("re_password", false);
        }
        break;
      case "terms_conditions_agreement":
        valid = value;
        updateFormErrorsObject("terms_conditions_agreement", !value);
        break;
      default:
        break;
    }

    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors({ ...formErrors, processFailed: false });
    setLoader(true);
    let keys = Object.keys(formValues);
    let errorFound = true;
    for (let index = 0; index < keys.length; index++) {
      errorFound = !validateFieldValues(keys[index], formValues[keys[index]]);
      if (errorFound) {
        break;
      }
    }

    if (userInvitationTeamHash && !errorFound) {
      talentsServiceObj
        .RegisterUser({
          ...formValues,
          hash_type: "team",
          hash: userInvitationTeamHash,
        })
        .then((response) => {
          if (response.httpStatus === 200 && response.json.data) {
            localStorage.setItem(
              Constants.MAZHR_JWT_KEY,
              JSON.stringify(response.json.data)
            );
            setLoader(false);
            navigate("/assessment/");
          } else {
            setFormErrors({ ...formErrors, processFailed: true });
            setLoader(false);
          }
        });
    }
    else if (userInvitationPoolHash && !errorFound) {
      talentsServiceObj
          .RegisterUser({
            ...formValues,
            hash_type: "pool",
            hash: userInvitationPoolHash,
          })
          .then((response) => {
            if (response.httpStatus === 200 && response.json.data) {
              localStorage.setItem(
                  Constants.MAZHR_JWT_KEY,
                  JSON.stringify(response.json.data)
              );
              setLoader(false);
              navigate("/assessment/");
            } else {
              setFormErrors({ ...formErrors, processFailed: true });
              setLoader(false);
            }
          });
    }
    else if (!errorFound) {
      setFormErrors({ ...formErrors, processFailed: true });
    } else {
      setLoader(false);
    }
  };

  // RENDER MAIN COMPONENT
  if (!loader && pageData) {
    let content = pageData.fields;

    return (
      <div className="auth-page-wrapper">
        <div className="form-wrapper">
          <h3 className="title semi-bold">{content.titleDesktop}</h3>
          {/* <p className="grey s">{content.subTitleDesktop}</p> */}
          <OnboardingStepper
            activeStepNumber={1}
            isTeamMember={invitationData?.team ? true : false}
          />

          <form className="form" onSubmit={handleSubmit}>
            {companyData?.name && (
              <p>
                {companyData.name} has invited you
                {teamData?.title && ` to team ${teamData.title}`} to take part
                in a few short assessments that will help to describe your work
                style, motivations and values.
              </p>
            )}
            <FormControl fullWidth>
              {formErrors.processFailed && (
                <Alert variant="outlined" severity="error">
                  {formErrorMessages.processFailed}
                </Alert>
              )}
              <TextField
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                  },
                }}
                placeholder="Start typing..."
                id="first"
                label={content.firstName || "First name"}
                name="first"
                type="text"
                autoComplete="on"
                value={formValues.first}
                error={formErrors.first}
                helperText={formErrors.first && formErrorMessages.first}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
              <br />
              <TextField
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                  },
                }}
                placeholder="Start typing..."
                id="last"
                label={content.lastName || "Last name"}
                name="last"
                type="text"
                autoComplete="on"
                value={formValues.last}
                error={formErrors.last}
                helperText={formErrors.last && formErrorMessages.last}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
              <br />
              {invitationData && userInvitationTemporaryToken ? (
                <TextField
                  InputLabelProps={{
                    sx: {
                      [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                    },
                  }}
                  id="email"
                  label={content.email || "Work email"}
                  name="email"
                  type="email"
                  value={formValues.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={null}
                  onBlur={null}
                />
              ) : (
                <TextField
                  InputLabelProps={{
                    sx: {
                      [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                    },
                  }}
                  placeholder="Start typing..."
                  id="email"
                  label={content.email || "Work email"}
                  name="email"
                  type="email"
                  autoComplete="on"
                  value={formValues.email}
                  error={formErrors.email}
                  helperText={formErrors.email && formErrorMessages.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              )}

              <br />
              <TextField
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                  },
                }}
                placeholder="Start typing..."
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="on"
                value={formValues.password}
                error={formErrors.password}
                helperText={formErrors.password && formErrorMessages.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
              <br />
              <TextField
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: { color: "#979797" },
                  },
                }}
                placeholder="Start typing..."
                id="re_password"
                label="Repeat Password"
                name="re_password"
                type="password"
                autoComplete="on"
                value={formValues.re_password}
                error={formErrors.re_password}
                helperText={
                  formErrors.re_password && formErrorMessages.re_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    name="terms_conditions_agreement"
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <span>
                    {content.termsAgreement}{" "}
                    <a
                      href={
                        content.termsOfServiceUrl ||
                        "https://www.apexteam.ai/terms-of-service"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.termsOfService || "terms of service "}
                    </a>
                    and{" "}
                    <a
                      href={
                        content.privacyPolicyUrl ||
                        "https://www.apexteam.ai/privacy-policy"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.privacyPolicy || "privacy policy"}
                    </a>
                  </span>
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
              {formErrors.terms_conditions_agreement && (
                <div className="input-error">
                  <p className="m-0">
                    {formErrorMessages.terms_conditions_agreement}
                  </p>
                </div>
              )}
            </FormControl>
            <div className="authButtons">
              {/* <Link className="mazhrBtn default-green white" to="/assessments"> */}
              <Button btnstyle={"mazhrBtn default-green white"} type="submit">
                {loader && !error ? (
                  <img className="loader" src={loading} alt="Loading..." />
                ) : (
                  <p className="m-0 light">
                    {content.signUpButton || "Register"}
                  </p>
                )}
              </Button>
              {/* </Link> */}
              <Link className="mazhrBtn light-green" to="/login">
                <p className="m-0 light">{content.signInButton || "Log In"}</p>
              </Link>
            </div>
            {/* TEMPORARIY DISABLE SOCIAL LOGIN */}
            {/* <div className="auth-social-login">
              {content.signInSocialConnects}
              <Link
                className="social-icon"
                to={Constants.MAZHR_APP_URL + "/#"}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={GoogleIcon} alt="Google" />
              </Link>
              <Link
                className="social-icon"
                to={Constants.MAZHR_APP_URL + "/#"}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-icon"
                  src={FacebookIcon}
                  alt="Facebook"
                />
              </Link>
              <Link
                className="social-icon"
                to={Constants.MAZHR_APP_URL + "/#"}
                target="_blank"
                rel="noreferrer"
              >
                <img className="social-icon" src={TwitterIcon} alt="Twitter" />
              </Link>
            </div> */}
          </form>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default RegistrationStage1;
