import TalentsApi from "./TalentsApi";
import * as Constants from "./constants";
import { store } from "./store/store";
import {setPoolData} from "./store/actions/poolDataActions";
import { setTeamData } from "./store/actions/teamDataActions";
import {
  updateUserAssessmentReportsAndData,
} from "./store/actions/userDataActions";

export default class TalentsApiService {
  talentsApiObj = new TalentsApi()

  Login = (data) => {
    return this.talentsApiObj.apiCall("auth/login/", "post", JSON.stringify(data));
  };

  LoadUserData = (setDataFunction) => {
    this.talentsApiObj.apiCall("user/info/", "get").then((res) => {
      if (res.httpStatus === 200 && res.json && res.json.data) {
        setDataFunction({
          email: res.json.data.email,
          first: res.json.data.first,
          last: res.json.data.last,
        });
      }
    });
  };

  LoadUserDataByTemporaryToken = (
    temporaryTokenHash,
    setDataFunction = null,
    setLoader
  ) => {
    setLoader && setLoader(true);
    this.talentsApiObj.apiCall("user/" + temporaryTokenHash, "get").then((res) => {
      if (res.httpStatus === 200) {
        setLoader && setLoader(false);
        if (setDataFunction) {
          setDataFunction(res.json.data);
        }
      }
    });
  };

  RegisterUser = (data) => {
    return this.talentsApiObj.apiCall(
      "talent/register/",
      "post",
      JSON.stringify(data)
    );
  };

  SaveUserData = (data) => {
    return this.talentsApiObj.apiCall("user/info/", "put", JSON.stringify(data));
  };

  GetAssessmentUrl = (
    providerName,
    instrumentId,
    languageId,
  ) => {
    return this.talentsApiObj.apiCall(
      `assessment/fetch-url/${providerName}/${instrumentId}/${languageId}`,
      "get"
    );
  };

  FetchAssessmentsResults = (providerName, instrumentId, languageId=2) => {
    return this.talentsApiObj.apiCall(
        `assessment/fetch-result/${providerName}/${instrumentId}/${languageId}/new`,
        "get"
    ).then((res) => {
      if (res.httpStatus === 200) {
        store.dispatch(updateUserAssessmentReportsAndData(res.json.data))
        return res.json.data; // Return the data from the promise
      }
    });
  }

  ToggleDataShareInviteStatus = (inviteType, status, declineReason=[], hash) => {
    return this.talentsApiObj.apiCall(
        `talent/toggle-shared-data-status/${inviteType}/${hash}`,
        "PUT",
        JSON.stringify({'status' : status, 'decline_reason': declineReason})
    ).then((res) => {
      if (res.httpStatus === 200) {
        return true
      }
    });
  }

  LoadIndustriesList = (setDataFunction) => {
    this.talentsApiObj.apiCall("job-industries/", "get").then((res) => {
      if (res.httpStatus === 200) {
        let industryData = [];
        res.json.map((data) =>
          industryData.push({
            id: data.id,
            title: data.name,
          })
        );

        setDataFunction(industryData);
      }
    });
  };

  LoadTargetProfilesByIndustry = (jobIndustryObj, setDataFunction) => {
    this.talentsApiObj
      .apiCall("target-profiles/" + jobIndustryObj.id, "get")
      .then((res) => {
        setDataFunction(res.json);
      });
  };

  ForgotPasswordEmail = (data, setDataFunction) => {
    return this.talentsApiObj.apiCall(
      "user/forgot_password/",
      "POST",
      JSON.stringify(data)
    ).then((res) => {
      setDataFunction(res.json);
    });
  };

  SetNewPassword = (obj) => {
    return this.talentsApiObj.apiCall(
      "user/reset_password/",
      "post",
      JSON.stringify(obj)
    );
  };

  ValidateResetPasswordKey = (obj) => {
    return this.talentsApiObj.apiCall(
        "user/reset_password_key_validate/",
        "post",
        JSON.stringify(obj)
    );
  };

  LoadPoolByHash = (hash, setDataFunction = null, setLoader) => {
    setLoader && setLoader(true);
    this.talentsApiObj.apiCall("pool/" + hash, "get").then((res) => {
      if (res.httpStatus === 200) {
        setLoader && setLoader(false);
        if (setDataFunction) {
          setDataFunction(res.json.data);
        }
        //store.dispatch(setPoolData(res.json.data[0]));
        store.dispatch(setPoolData(res.json.data));
      }
    });
  };

  LoadPoolsWithIdAndTitle = (setDataFunction) => {
    this.talentsApiObj.apiCall("pool/", "get").then((res) => {
      if (res.httpStatus === 200) {
        let poolData = [];
        res.json.data.map((data) =>
          poolData.push({
            id: data.hash,
            title: data.title.toUpperCase(),
          })
        );
        setDataFunction(poolData);
      }
    });
  };

  LoadPoolsWithAllData = (setDataFunction, setLoader) => {
    setLoader && setLoader(true);
    this.talentsApiObj.apiCall("pool/", "get").then((res) => {
      if (res.httpStatus === 200) {
        setDataFunction(res.json.data);
        setLoader && setLoader(false);
      }
    });
  };


  LoadTeamByHash = (hash, setDataFunction = null, setLoader) => {
    setLoader && setLoader(true);
    this.talentsApiObj.apiCall("team/" + hash, "get").then((res) => {
      if (res.httpStatus === 200) {
        setLoader && setLoader(false);
        if (setDataFunction) {
          setDataFunction(res.json.data);
        }
        store.dispatch(setTeamData(res.json.data[0]));
      }
    });
  };

  LoadTeamsWithAllData = (setDataFunction, setLoader) => {
    setLoader && setLoader(true);
    this.talentsApiObj.apiCall("team/", "get").then((res) => {
      if (res.httpStatus === 200) {
        setDataFunction(res.json.data);
        setLoader && setLoader(false);
      }
    });
  };

  LoadAssessments = (setDataFunction) => {
    this.talentsApiObj.apiCall("assessments/", "get").then((res) => {
      setDataFunction(res.json.data);
    });
  };


  InviteTalentToPool = (userId, isAnonymous, poolHash, callBackFunc) => {
    if (isAnonymous) {
      isAnonymous = 1;
    } else {
      isAnonymous = 2;
    }
    this.talentsApiObj
      .apiCall(
        "invite-talent/" +
          userId +
          "/anonymous/" +
          isAnonymous +
          "/pool/" +
          poolHash,
        "post"
      )
      .then((data) => {
        if (data.httpStatus === 200 && data.json) {
          callBackFunc(data.json);
        }
      });
  };

  GetCurrentUserDetails = (callBack) => {
    this.talentsApiObj.apiCall("talent/get-details", "GET").then((res) => {
      if (res.httpStatus === 200 && res.json) {
        callBack(res.json);
      }
    });
  };

  EmailExist = (email, doesExistFunc) => {
    if (email === "" || email === undefined || !email) {
      doesExistFunc(false);
      return;
    }

    this.talentsApiObj.apiCall("exist/email/" + email, "post").then((data) => {
      if (data.httpStatus === 200 && data.json) {
        doesExistFunc(data.json.exist);
      }
    });
  };


  ResendTeamInvite = (payload, callbackFunction) => {
    this.talentsApiObj
      .apiCall("resend-team-invite", "POST", JSON.stringify(payload))
      .then((res) => {
        if (res.httpStatus === 200) {
          if (res.json) {
            callbackFunction(res.json);
          }
        } else {
          callbackFunction([]);
        }
      });
  };

  FetchDataShareInvites = (callBackFunction) => {
    this.talentsApiObj.apiCall("talent/shared-data/", "GET").then((res) => {
      if (res.httpStatus === 200) {
        if (res.json) {
          let data = Object.values(res.json.data).map((item, index) => {
            // These fields are similar between 'team_invite' and 'job_invite'
            let similarFields = {
              id: index, // Assuming you want the 'status' as the new 'id'
              company: item.company?.name, // Accessing the 'name' property inside 'company'
              status: item.status, // value is 1 (invitation sent),2 (invitation accepted), or 3 (invitation rejected)
              dataAccess: item.shared, // value is true or false
              type: item.type, // value is 'team_invite' or 'job_invite'
            };

            if (item.type === "team_invite") {
              return {
                team: item.team?.title, // Accessing the 'title' property inside 'team'
                requested_assessments: Constants.AON_ASSESSMENTS.map(
                  (assessmentItem) => {
                    if (
                      item.team?.assessments?.hasOwnProperty(assessmentItem.id)
                    ) {
                      return {
                        ...assessmentItem,
                        completed: item.team?.assessments?.[assessmentItem.id],
                      };
                    } else {
                      return { ...assessmentItem, completed: false };
                    }
                  }
                ),
                about: item.team?.description, // Using the 'description' property from 'team' as 'about',
                ...similarFields,
              };
            }
            if (item.type === "job_invite") {
              return {
                job: item.pool?.title, // Accessing the 'title' property inside 'pool'
                requested_assessments: Constants.AON_ASSESSMENTS.map(
                  (assessmentItem) => {
                    if (
                      item.pool?.assessments.hasOwnProperty(assessmentItem.id)
                    ) {
                      return {
                        ...assessmentItem,
                        completed: item.pool?.assessments?.[assessmentItem.id],
                      };
                    } else {
                      return { ...assessmentItem, completed: false };
                    }
                  }
                ),
                about: item.pool?.description, // Using the 'description' property from 'pool' as 'about',
                ...similarFields,
              };
            }
            return "";
          });
          callBackFunction(data);
        }
      } else {
        callBackFunction([]);
      }
    });
  };

  GetCompetencePointsList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
    this.talentsApiObj.apiCall('competence-report/', 'post', JSON.stringify(payload)).then((data) => {
      if (data.httpStatus === 200) {
        const columnList = data.json.data.group_list;
        columnList.map(columnList => {
          columnList.columns_list.map(column => {
            var camelCased = column?.column_name.replace(/_([a-z])/g, function (g) {
              return g[1]?.toUpperCase();
            });
            if(definitions) {
              column.tool_tip = definitions[camelCased]
            }

            return true;
          })
          return columnList

        });
        let userData = data.json.data.user_data;
        userData = userData.map(val => ({...val, isSelected: false}));
        let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
        setSelectOptions(selectOptionsArray)
        setDataTableList(userData);
        setAllCol(columnList);
        setLoader(false);
      } else {
        setDataTableList([]);
        setLoader(false);
      }
    })
  }

  GetAdeptAspectsList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
    this.talentsApiObj.apiCall('adept-15/', 'post', JSON.stringify(payload)).then((data) => {
      if (data.httpStatus === 200) {
        const columnList = data.json.data.group_list;
        columnList.map(columnList => {
          columnList.columns_list.map(column => {
            var camelCased = column?.column_name.replace(/_([a-z])/g, function (g) {
              return g[1]?.toUpperCase();
            });
            if(definitions) {
              column.tool_tip = definitions[camelCased]
            }

            return true;
          })
          return columnList

        });
        let userData = data.json.data.user_data;
        userData = userData.map(val => ({...val, isSelected: false}));
        let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
        setSelectOptions(selectOptionsArray)
        setDataTableList(userData);
        setAllCol(columnList);
        setLoader(false);
      } else {
        setDataTableList([]);
        setLoader(false);
      }
    })
  }

  GetMotivationStrengthList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
    this.talentsApiObj.apiCall('motivation-report/', 'post', JSON.stringify(payload)).then((data) => {
      if (data.httpStatus === 200 && data.json.data.group_list) {
        const columnList = data.json.data.group_list;
        if (definitions && Object.keys(definitions).length > 0) {
          columnList.map(columnList => {
            columnList.columns_list.map(column => {
              var camelCased = column.column_name.replace(/_([a-z])/g, function (g) {
                return g[1].toUpperCase();
              });
              column.tool_tip = definitions[camelCased]
              return true;
            })
            return columnList

          });
        }
        let userData = data.json.data.user_data;

        userData = userData.map(val => ({...val, isSelected: false}));
        let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
        setSelectOptions(selectOptionsArray)
        setDataTableList(userData);
        setAllCol(columnList);
        setLoader(false);
      } else {
        setDataTableList([]);
        setLoader(false);
      }
    })
  }

  getSalesData = (payload, setTableColoumns, setTableData, setcolList,contentfulData, setLoader) => {
    this.talentsApiObj.apiCall('sales-report/', 'post', JSON.stringify(payload)).then((res) => {
      if(res.httpStatus===200 ) {
        let columnList = [];

        const col = res.json.data.group_list
        const tableCols = col[0].columns_list.map(column =>{
          var camelCased = column.column_name.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
          column.tool_tip = contentfulData[camelCased]
          return column
        });
        setTableColoumns([...tableCols]);
        const userData = res.json.data.user_data;
        setTableData([...userData]);
        col.forEach((val) => {
          columnList.push({ id: val.group_name, title: val.group_name });
        });
        columnList.push({ id: 'comp&attitude', title: 'Competency & Attitude' });
        setcolList([...columnList]);
        setLoader(false);
      }
      else {
        setLoader(false);
        setTableData([]);
      }}

    )

  }
  GetTeamStrengthList = (payload, definitions, setDataTableList, setAllCol, setLoader) => {
    this.talentsApiObj.apiCall('team-strength-report/', 'post', JSON.stringify(payload)).then((data) => {
      if (data.httpStatus === 200 && data.json.data.group_list) {
        const col = data.json.data.group_list;
        col.map(column => {
          var camelCased = column.column_name.replace(/_([a-z])/g, function (g) {
            return g[1].toUpperCase();
          });
          column.tool_tip = definitions[camelCased]
          return true;
        });
        let userData = data.json.data.user_data;
        userData = userData.map(val => ({...val, isSelected: false}));
        setDataTableList(userData);
        setAllCol(col);
        if(setLoader) {
          setLoader(false);
        }


      } else {
        setDataTableList([]);
        setLoader(false);
      }
    })
  }
}
